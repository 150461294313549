import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Button from "../Button";

const OptionsHeaderWrapper = styled.div`
    background: ${(props) => props.theme.palette.secondary.dark};
    border-top-left-radius: ${(props) => props.theme.spacing.unit}px;
    border-top-right-radius: ${(props) => props.theme.spacing.unit}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.spacing.unit * 2}px;
`;

const OptionsHeaderTitle = styled.h3`
    color: ${(props) => props.theme.palette.text.secondary};
    margin: 0;
`;

const OptionsHeader = ({ handleDeleteOptions }) => (
    <OptionsHeaderWrapper>
        <OptionsHeaderTitle>Your Options</OptionsHeaderTitle>
        <Button skeleton onClick={handleDeleteOptions}>
            Remove all
        </Button>
    </OptionsHeaderWrapper>
);

OptionsHeader.propTypes = {
    handleDeleteOptions: PropTypes.func.isRequired
};

export default OptionsHeader;
