import PropTypes from "prop-types";
import React from "react";
import Button from "../Button";
import Modal from "styled-react-modal";

const StyledModal = Modal.styled`
    background-color: ${(props) => props.theme.palette.primary.light};
    border-radius: 10px;
    color: ${(props) => props.theme.palette.primary.contrastText};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${(props) => props.theme.spacing.unit * 4}px;
    margin: ${(props) => props.theme.spacing.unit * 3}px;
    width: ${(props) => props.theme.breakpoints.sm}px;
    word-break: break-all;
`;

const ActionModal = ({ selectedOption, handleClearPick }) => (
    <StyledModal
        appElement={document.getElementById("root")}
        isOpen={!!selectedOption}
        contentLabel="Selected Option"
        onBackgroundClick={handleClearPick}
        onEscapeKeydown={handleClearPick}>
        <h3>Selected Option</h3>
        {selectedOption && <p>{selectedOption}</p>}
        <Button secondary onClick={handleClearPick}>
            Ok
        </Button>
    </StyledModal>
);

ActionModal.propTypes = {
    selectedOption: PropTypes.string,
    handleClearPick: PropTypes.func.isRequired
};

export default ActionModal;
