import React from "react";
import styled from "styled-components";
import Container from "../../layout/Container";

const HeaderWrapper = styled.div`
    background: ${(props) => props.theme.palette.primary.main};
    margin-bottom: ${(props) => props.theme.spacing.unit * 3}px;
    padding: 0 ${(props) => props.theme.spacing.unit * 2}px;
`;

const HeaderTitle = styled.h1`
    color: ${(props) => props.theme.palette.text.primary};
    font-size: ${(props) => props.theme.typography.fontSize * 2}px;
    margin: 0;
`;

const HeaderSubtitle = styled.h2`
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: ${(props) => props.theme.typography.fontSize}px;
    font-style: italic;
    margin: 0;
`;

const Header = () => (
    <HeaderWrapper>
        <Container>
            <HeaderTitle>Decisionmakr</HeaderTitle>
            <HeaderSubtitle>Let's decide your next action!</HeaderSubtitle>
        </Container>
    </HeaderWrapper>
);

export default Header;
