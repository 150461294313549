import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Button from "../Button";

const OptionWrapper = styled.div`
    background: ${(props) => props.theme.palette.secondary.light};
    border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.spacing.unit * 2}px;
`;

const OptionText = styled.div`
    color: ${(props) => props.theme.palette.secondary.contrastText};
    word-break: break-all;
`;

const Option = ({ optionText, optionCount, handleDeleteOption }) => (
    <OptionWrapper>
        <OptionText>
            {optionCount}. {optionText}
        </OptionText>
        <Button skeleton onClick={(e) => handleDeleteOption(optionText)}>
            Remove
        </Button>
    </OptionWrapper>
);

Option.propTypes = {
    optionText: PropTypes.string.isRequired,
    optionCount: PropTypes.number.isRequired
};

export default Option;
