import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Container from "../../layout/Container";
import Button from "../Button";

const ActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Action = ({ hasOptions, handlePick }) => (
    <Container>
        <ActionWrapper>
            <Button big fullWidth disabled={!hasOptions} onClick={handlePick}>
                What should I do?
            </Button>
        </ActionWrapper>
    </Container>
);

Action.propTypes = {
    hasOptions: PropTypes.bool.isRequired,
    handlePick: PropTypes.func.isRequired
};

export default Action;
