import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Container from "../../layout/Container";
import AddOption from "./AddOption";
import Option from "./Option";
import OptionsHeader from "./OptionsHeader";

const OptionsEmptyMessage = styled.div`
    background: ${(props) => props.theme.palette.secondary.light};
    border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};
    color: ${(props) => props.theme.palette.text.secondary};
    display: flex;
    justify-content: center;
    font-style: italic;
    padding: ${(props) => props.theme.spacing.unit * 2}px;
`;

const Options = ({
    options,
    handleAddOption,
    handleDeleteOptions,
    handleDeleteOption
}) => (
    <Container>
        <OptionsHeader handleDeleteOptions={handleDeleteOptions} />
        {options.length === 0 && (
            <OptionsEmptyMessage>
                Please add an option to get started!
            </OptionsEmptyMessage>
        )}
        {options.map((option, index) => (
            <Option
                key={option}
                optionText={option}
                optionCount={index + 1}
                handleDeleteOption={handleDeleteOption}
            />
        ))}
        <AddOption handleAddOption={handleAddOption} />
    </Container>
);

Options.propTypes = {
    options: PropTypes.array.isRequired,
    handleAddOption: PropTypes.func.isRequired,
    handleDeleteOptions: PropTypes.func.isRequired,
    handleDeleteOption: PropTypes.func.isRequired
};

export default Options;
