import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import Action from "./components/Action/Action";
import ActionModal from "./components/Action/ActionModal";
import Header from "./components/Header/Header";
import Options from "./components/Options/Options";

const theme = {
    breakpoints: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    },
    palette: {
        primary: {
            light: "#6573c3",
            main: "#3f51b5",
            dark: "#2c387e",
            contrastText: "whitesmoke"
        },
        secondary: {
            light: "#4D4D4D",
            main: "#333333",
            dark: "#1A1A1A",
            contrastText: "whitesmoke"
        },
        text: {
            primary: "rgba(255, 255, 255, 0.87)",
            secondary: "rgba(255, 255, 255, 0.54)",
            disabled: "rgba(255, 255, 255, 0.38)",
            hint: "rgba(255, 255, 255, 0.38)"
        }
    },

    spacing: {
        unit: 8
    },
    typography: {
        fontSize: 16
    }
};

class App extends Component {
    state = {
        options: [],
        selectedOption: undefined
    };

    componentDidMount() {
        try {
            const json = localStorage.getItem("options");
            const options = JSON.parse(json);

            if (options) {
                this.setState(() => ({ options }));
            }
        } catch (e) {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.options.length !== this.state.options.length) {
            const json = JSON.stringify(this.state.options);
            localStorage.setItem("options", json);
        }
    }

    handlePick = () => {
        const randomNum = Math.floor(Math.random() * this.state.options.length);
        const option = this.state.options[randomNum];
        this.setState(() => ({
            selectedOption: option
        }));
    };

    handleClearPick = () => {
        this.setState(() => ({ selectedOption: undefined }));
    };

    handleAddOption = (option) => {
        if (!option) {
            return "Enter valid value to add item!";
        } else if (this.state.options.indexOf(option) > -1) {
            return "This option already exists!";
        }

        this.setState((prevState) => ({
            options: prevState.options.concat([option])
        }));
    };

    handleDeleteOptions = () => {
        this.setState(() => ({ options: [] }));
    };

    handleDeleteOption = (option) => {
        this.setState((prevState) => ({
            options: prevState.options.filter(
                (prevOption) => prevOption !== option
            )
        }));
    };

    render() {
        const { options, selectedOption } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <ModalProvider>
                    <Header />
                    <Action
                        handlePick={this.handlePick}
                        hasOptions={options.length > 0}
                    />
                    <Options
                        options={options}
                        handleAddOption={this.handleAddOption}
                        handleDeleteOptions={this.handleDeleteOptions}
                        handleDeleteOption={this.handleDeleteOption}
                    />
                    <ActionModal
                        selectedOption={selectedOption}
                        handleClearPick={this.handleClearPick}
                    />
                </ModalProvider>
            </ThemeProvider>
        );
    }
}

export default App;
