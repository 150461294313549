import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Button from "../Button";

const AddOptionWrapper = styled.div`
    background: ${(props) => props.theme.palette.secondary.light};
`;

const AddOptionForm = styled.form`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing.unit * 2}px;

    ${breakpoint("sm")`
        flex-direction: row;
    `}
`;

const OptionInput = styled.input`
    background: ${(props) => props.theme.palette.secondary.main};
    border: none;
    border-bottom: 0.3rem solid ${(props) => props.theme.palette.secondary.dark};
    color: ${(props) => props.theme.palette.secondary.contrastText};
    flex-grow: 1;
    min-height: 0;
    margin-bottom: ${(props) => props.theme.spacing.unit}px;
    padding: ${(props) => props.theme.spacing.unit}px;

    ${breakpoint("sm")`
        margin-bottom: 0;
    `}
`;

const ErrorMessage = styled.div`
    color: red;
    display: flex;
    justify-content: center;
    font-style: italic;
    padding: ${(props) => props.theme.spacing.unit * 2}px;
`;

export default class AddOption extends Component {
    state = {
        error: undefined
    };

    handleAddOption = (e) => {
        e.preventDefault();

        const option = e.target.elements.option.value.trim();
        const error = this.props.handleAddOption(option);

        this.setState(() => ({ error }));

        if (!error) {
            e.target.elements.option.value = "";
        }
    };

    render() {
        return (
            <AddOptionWrapper>
                {this.state.error && (
                    <ErrorMessage>{this.state.error}</ErrorMessage>
                )}
                <AddOptionForm onSubmit={this.handleAddOption}>
                    <OptionInput type="text" name="option" autoComplete="off" />
                    <Button>Add Option</Button>
                </AddOptionForm>
            </AddOptionWrapper>
        );
    }
}

AddOption.propTypes = {
    handleAddOption: PropTypes.func.isRequired
};
