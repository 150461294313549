import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: ${(props) => props.theme.spacing.unit}px
        ${(props) => props.theme.spacing.unit * 2}px;
`;

const Container = (props) => <StyledContainer {...props} />;

export default Container;
