import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const StyledButton = styled.button`
    background-color: ${(props) => props.theme.palette.primary.light};
    border: none;
    border-bottom: 0.3rem solid ${(props) => props.theme.palette.primary.dark};
    color: ${(props) => props.theme.palette.text.primary};
    cursor: pointer;
    font-weight: 500;
    padding: ${(props) => props.theme.spacing.unit}px;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &:active {
        background-color: ${(props) => props.theme.palette.primary.main};
    }

    ${({ fullWidth }) => fullWidth && FullWidth}
    ${({ secondary }) => secondary && Secondary}

    ${({ big }) => big && BigButton}
    ${({ skeleton }) => skeleton && SkeletonButton}
`;

const FullWidth = css`
    width: 100%;
`;

const Secondary = css`
    background-color: ${(props) => props.theme.palette.secondary.light};
    border-bottom: 0.3rem solid ${(props) => props.theme.palette.secondary.dark};

    &:active {
        background-color: ${(props) => props.theme.palette.secondary.main};
    }
`;

const BigButton = css`
    border-bottom: 0.6rem solid ${(props) => props.theme.palette.primary.dark};
    font-size: 1.5rem;
    font-weight: bold;
    padding: ${(props) => props.theme.spacing.unit * 3}px;

    ${breakpoint("sm")`
        font-size: 2rem;
    `}
`;

const SkeletonButton = css`
    background: transparent;
    border: none;
    color: ${(props) => props.theme.palette.text.secondary};
`;

const Button = (props) => <StyledButton {...props} />;

Button.propTypes = {
    primary: PropTypes.bool,
    skeleton: PropTypes.bool,
    fullWidth: PropTypes.bool
};

export default Button;
